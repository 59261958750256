import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import TapSelect from "../../../components/ui/TapSelect";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import {DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import DownloadFile from "../../../services/DownloadFile";
import Status from '../../../components/ui/Status';
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class AssetStatus extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportFilterFormInitialState       =   {
            site_group_ids                          :       [],
            search_site_ids                         :   [],
            'asset_group_ids'                       :    [],
            search_asset_type_ids                   :   [],
            search_asset_matrix_ids                 :   [],
            search_manufacturer_ids                 :   [],
            search_models                           :   [],
            search_asset_ids                        :   [],
            search_asset_category                   :   'Y',
            search_cl_aging                         :   '',
            search_status                           :   [],
        };
        
        this.reportFilterFormLabel              =   {
            site_group_ids                          :   'Site Groups : ',
            search_site_ids                         :   'Sites : ',
            'asset_group_ids'                       :   'Asset Type Groups : ',
            search_asset_type_ids                   :   'Asset Type : ',
            search_asset_matrix_ids                 :   'Asset Matrix : ',
            search_manufacturer_ids                 :   'Manufacturer : ',
            search_models                           :   'Models : ',
            search_asset_ids                        :   'Assets : ',
            search_asset_category                   :   'Asset Category : ',
            search_cl_aging                         :   'Counter Log Aging : ',
            search_status                           :   'Asset Status : ',
        }
        
        this.state                              =   {
            iam_asset_types                         :   [],
            iam_user_sites                          :   [],
            iam_asset_matrix                        :   [],
            iam_manufactures                        :   [],
            iam_models                              :   [],
            iam_asset_category                      :   [],
            formDataLoaded                          :   false,
            filterFormData                          :   null,
            searchedAssets                          :   [],
            reportFilterForm                        :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm               :   {...this.reportFilterFormInitialState},
            listing_loading                         :   false,
            downloadReportBtn                       :   false,
            formSearchedElems                       :   [],
            listingData                             :   [],
            listingMeta                             :   null,
            reportDownloadBtn                       :   false,
            reportDownloading                       :   false,
            iam_status_list                         :   [],
            allAssetTypesGroupsList                 :   [],
            reportData                              :   null,                  
        };

        this.formDataUrl        =   IAM_API_BASE_URL_2 + '/report/asset-status/form_data';
        this.reportUrl          =   IAM_API_BASE_URL_2 + '/report/asset-status';

    }
    
    componentDidMount() {
        this.initilaizeAppDataToFilterForm(this.props);
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }
    
    initilaizeAppDataToFilterForm           =   (props)  =>  {

         const report_name   =   'asset_status';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];

        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
            iam_asset_types         :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
            iam_user_sites          :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
            iam_asset_matrix        :   props.iam_asset_matrix ? props.iam_asset_matrix.map(am => { return {value: am.id, label: am.asset_matrix_unique_id}}) : [],
            iam_manufactures        :   props.iam_manufactures ? props.iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
            iam_models              :   props.iam_models ? props.iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
            iam_status_list         :   props.status_list && props.status_list.length > 0 ? props.status_list.map((s) => { return {value: s.key, label: s.name}}) : [],
            allAssetTypesGroupsList :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
        }, () => this.filterFormDataInit());
    }
    
    filterFormDataInit                      =   ()  =>  {
        HttpAPICall.withAthorization('GET', this.formDataUrl, this.props.access_token, null, null, (response) => {
            let respData = response.data;
            this.setState({
                formDataLoaded              :   true,
                iam_asset_category          :   response.data.asset_category.map(ac => { return {value: ac.key, label: ac.name}}),
                allSiteGroupsList       :       respData && respData.site_groups && respData.site_groups.length > 0 ? respData.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
            });
            
            
            let ParamObject                 =   new URLSearchParams(this.props.location.search);
            
            if(ParamObject.get('ageing') || ParamObject.get('site_id')) {
                let updateFilterForm        =   {search_cl_aging : ParamObject.get('ageing')};
                if(ParamObject.get('site_id')) {
                    let site                =   this.props.iam_user_sites.find(us => us.id == ParamObject.get('site_id'));
                    if(site) {
                        updateFilterForm['search_site_ids'] =   [site.id];
                    }
                }
                this.setState({reportFilterForm :   {...this.state.reportFilterForm, ...updateFilterForm}});
            }
            this.reportFilterFormSubmit();
        }, (err) => { });
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        let serachFormKeys                      =   Object.keys(this.state.reportFilterForm);
        
        let searchedElems                       =   [];
        let assetSearchParams                   =   {};
        
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.length > 0) {
                assetSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_category') {
                        show_val            = this.state.iam_asset_category.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_status') {
                        show_val            =   this.state.iam_status_list.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_matrix_ids') {
                        show_val            = this.state.iam_asset_matrix.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
            this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page =1)  =>  {
        this.setState({
            listing_loading         :   true,
            listingData             :   [],
            listingMeta             :   null,
            listing_tbl_page        :   page,
            reportDownloadBtn       :   false,
            reportDownloading       :   false
        });
        let params                          =   {page:page,...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {
            
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }
    
    reportDownloadHandler                  =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, download : 'download'} , {} , (response) => {
            DownloadFile.file(response.data.file_path,response.data.msg,response.data.navigation,this.props)
        } ) .then(() => this.setState({reportDownloading: false}));
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    reportSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :           [],
            reportFilterForm                :           {...this.reportFilterFormInitialState},
            submittedReportFilterForm       :           {...this.reportFilterFormInitialState},
        }, () => {
            this.loadListingTblData(1);
        });
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCarAlt} /> Asset Status</h3>
                <div className="text-end mt15">
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report
                        {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null}
                    <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" disabled={!this.state.formDataLoaded} className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button> 
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  =>  {
        return (<div id="reportFilterForm" className="bg-white collapse" >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Matrix</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_matrix}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_matrix_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_matrix.filter(s => this.state.reportFilterForm.search_asset_matrix_ids.includes(s.value))}
                            placeholder="Select Asset Matrix"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            menuPlacement="top"
                            value={this.state.iam_manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({searchedAssets : selectedOption && selectedOption.length > 0 ? selectedOption : []});
                            }}
                            isMulti={true}
                            menuPlacement="top"
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Category</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_category}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_category')}
                            value={this.state.iam_asset_category.find(r => r.value ===  this.state.reportFilterForm.search_asset_category  )}
                            placeholder="ALL"
                            menuPlacement="top"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_status_list}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_status')}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_status_list.filter(s => this.state.reportFilterForm.search_status.includes(s.value))}
                            placeholder="Select Status"
                            menuPlacement="top"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Counter Log Aging</label>
                    <div className="col-sm-6">
                        <input
                            name="search_cl_aging"
                            type="text"
                            value={this.state.reportFilterForm.search_cl_aging}
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Counter Log Aging"
                        />
                    </div>
                    <div className="col-sm-3 form-text">
                        Example. 0-5 for a range or 4 for specific Aging
                    </div>
                </div>
                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr/>
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
            { this.state.listing_loading
                ?   <Loader />
                :   (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered table-fixed table-sm align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th style={{width:"17%"}}>Asset</th>
                        <th style={{width:"10%"}}>Asset Type</th>
                        <th style={{width:"12%"}}>Site</th>
                        <th className="text-center" style={{width:"10%"}}>Latest <br/>Counterlog</th>
                        <th className="text-center" style={{width:"8%"}}>Counterlog Ageing</th>
                        <th className="text-center" style={{width:"10%"}}>No. Of CL in Past 30 Days</th>
                        <th className="text-center" style={{width:"9%"}}>Current Status</th>
                        <th style={{width:"9%"}}>Status Since</th>
                        <th style={{width:"15%"}}>Status Remarks</th>
                    </tr>
                </thead>
                <tbody>
                { this.state.listingData.length > 0 
                    ? ( <Ax> { this.state.listingData.map((rowData,k) => (<tr key={k}>
                            <td><Link to={`/asset/dashboard/${rowData.id}?=Y`} role="button" className="link-primary">{rowData.name}</Link><br/><small>Code: {rowData.asset_code}</small></td>
                            <td>{rowData.asset_type_name}</td>
                            <td>{rowData.site_name}</td>
                            <td className="text-center" dangerouslySetInnerHTML={{__html: (rowData.latest_cl ? rowData.latest_cl.display.join("<br/>") : '-' ).replace(/(<? *script)/gi, 'illegalscript')}} ></td>
                            <td className="text-center">{rowData.last_cl_aging}</td>
                            <td className="text-center">{rowData.cl_count_in_30_days}</td>
                            <td><Status color={rowData.status_color_code}>{rowData.status_text}</Status></td>
                            <td>{rowData.status_change_date}</td>
                            <td className="fs12">{rowData.reason_for_status}</td>
                        </tr>)) 
                        }</Ax> )
                    :   (<tr><td className="text-center" colSpan={9}>No Record Found</td></tr>)
                }
                </tbody>
            </table>
        </Ax>);
    }
    
    render                                  =   ()  =>  {
        return (<ApplicationLayout> 
         <Helmet><title>Asset Status - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                            ?   <Ax>
                                    {this.reportFilterFormJsx()}
                                    <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                    {this.reportListingDisplayJsx()}
                                </Ax>
                            :   <Loader  />
                        }
                        {this.state.listingMeta 
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected+1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_asset_matrix        :   state.app && state.app.asset_matrix ? state.app.asset_matrix : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        status_list             :   state.app && state.app.status_list ? state.app.status_list : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(AssetStatus);
import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import Status from '../../../components/ui/Status';
import TapSelect from '../../../components/ui/TapSelect';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import WarehouseAddress from '../includes/WarehouseAddress';
import AssetMiniDetail from '../../includes/AssetMiniDetail';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import AddItemModal from '../../items/itemsAdd/AddItemModal';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ItemEditModal from '../../items/itemsAdd/ItemEditModal';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ConsumptionHistoryModal from './ConsumptionHistoryModal';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import ItemSearchModal from '../../items/itemSearchModal';
import axios from 'axios';
import InputTicketSearch from '../../includes/ui/InputTicketSearch';
import InputSubAssemblySearch from '../../includes/ui/InputSubAssemblySearch';
import TapInputNumber from '../../../components/ui/TapInputNumber';
import DateService from '../../../services/DateService';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';

class ConsumptionAdd extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.initConsumptionForm        =   {
            transaction_date                :   '',
            warehouse_id                    :   '',
            consumption_type                :   'asset',
            workstation_id                  :   '',
            ticket_id                       :   '',
            asset_id                        :   '',
            voucher_number                  :   '',
            voucher_date                    :   '',
            items                           :   [],
            notes                           :   '',
            adjustment_amount               :   0,
            ticket_id                       :   '',
            sub_assembly_id                 :   '',
            employee_id                     :   ''
        }
        
        this.iniitConsumptionItemRows   =   [{
            item                            :   null,
            consumption_item_id             :   null,
            remark                          :   '',
            amount                          :   0,
            qty                             :   0,
            stock_qty                       :   0,
            free_qty                        :   0,
            rate                            :   0,
            current_stocks                  :   [],
            selected_stock                  :   null,
            stock_loading                   :   false,
            selected_allocation             :   null,
            allocation_list                 :   [],
            allApiCallsCompleted            :   false,
        }];
        
        this.initalState                =   {
            consumptionFormDataLoaded       :   false,
            allWarehouses                   :   [],
            allGstSlabs                     :   [],
            allStatus                       :   [],
            isc_configuration               :   null,
            track_batch_expiry              :   'N',
            negative_stock                  :   'N',
            consumptionForm                 :   this.initConsumptionForm,
            saveFormSubmitting              :   false,
            consumptionDetail               :   null,
            selectedSite                    :   null,
            warehouseDetailLoading          :   false,
            warehouseDetail                 :   null,
            warehouseSites                  :   [],
            selectedAsset                   :   null,
            searchedWorkstation             :   null,
            consumptionItemRows             :   [...this.iniitConsumptionItemRows] ,
            itemRowNumber                   :    0  ,
            cloneConsumption                :    false,
            consumtionHistoryList           :      [],
            consumptionDetailLoading        :    false,
            only_stocked_item               :    'Y',
            update_item_consumption_price  :      'Y',
            searchedEmployee                :      null,
            pendingAllocationListLoaded     :      false,
            pending_item_loading            :      false,
            allPendingAllocationList        :      [],
            allAllocatedItemIds             :      [],
            addConsumptionFromPurchase      :      false,
            selectedTicket                  :      null,
            ticketFromConsumption           :      false,
            ticketLinkedFromAllocation      :       false,
            ticket_consumption              :       'N',
            buttonDisabled                  :       false,
            searchSubassembly               :   null,
            disableForTicket                :   false,
            ticketFromTicket                :   false,
            allConsumptionFor               :   [],
            include_item_demands       :   'Y',
            searchedWorkstation             :        null,
            showItemSearchModal             :       false,
            showItemAddModal                :       false,
            showItemEditModal               :       false
        }
        
        this.state                      =   {
            ...this.initalState
        }
        this.addItemModalRef            =   React.createRef();
        this.editItemModalRef           =   React.createRef();
        this.consumptionHistoryRef      =   React.createRef();
        this.itemSearchRef              =   React.createRef();
        
        this.CONSUMPTION_URL            =   INVENTORY_API_BASE_URL_2 + '/consumption';
        this.itemCurrentStockUrl        =   INVENTORY_API_BASE_URL_2 + '/item_current_stock';
        this.itemDetailUrl              =   INVENTORY_API_BASE_URL_2 + '/item';
        this.warehouseUrl               =   ISC_API_BASE_URL_2  +   '/warehouse';


    }
    
    componentDidMount() {
    
        this.viewItemModal                           =   new Modal(document.getElementById('viewItemModal'), {keyboard: false, backdrop :false});
        this.pickAllocationItemModal                 =   new Modal(document.getElementById('pickAllocationItemModal'), {keyboard: false, backdrop :false});
        
        setTimeout(() => {
            this.initilaizeFormData(this.props); 
        }, 1000);
        
    }

   
    initilaizeFormData                  =   (pr)  =>  {
        let sites                       =   pr.iam_group_sites;
        
         if(!this.state.consumptionFormDataLoaded) {
            
            HttpAPICall.withAthorization('GET', this.CONSUMPTION_URL + '/add_form_data', this.props.access_token, null, null, (response) => {
                 let formData = response.data
                this.setState({
                    consumptionFormDataLoaded   :   formData ? true : false,
                    allConsumptionFor            :   [{value:'asset',label:'Asset'},{value:'workstation',label:'Workstation'},{value:'employee',label:'Employee'},{value:'sub_assembly',label:'Sub Assembly'}],
                    allWarehouses               :   pr.all_warehouses && pr.all_warehouses.length > 0 ? pr.all_warehouses.map((s) => { return {value: s.id, label: `${s.name} (${s.code})` , site_id : s.site_ids}; }) : [],
                    allGstSlabs                 :   formData && formData.gst_slabs ? formData.gst_slabs.map((s) => { return {value: s, label: s +'%' }}) : [],
                    ticket_consumption          :   formData && formData.setting && formData.setting.ticket_consumption ? formData.setting.ticket_consumption : 'N',
                    allStatus                   :   formData && formData.status && formData.status.length > 0 ?  formData.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    track_batch_expiry          :   pr.isc_configuration && pr.isc_configuration.track_batch_expiry ? pr.isc_configuration.track_batch_expiry : 'N',
                    negative_stock              :   pr.isc_configuration && pr.isc_configuration.negative_stock ? pr.isc_configuration.negative_stock : 'N',
                }, () => {

                    let ParamObject                 =       new URLSearchParams(this.props.location.search);
                    /********************************EDIT CONSUMPTION ************************************* */
                    if(pr.location && pr.location.state && pr.location.state.consumptionData) {
                       this.setState({buttonDisabled: true})
                        let consumptionDetail       =   pr.location.state.consumptionData;
                        let consumedItems           =   consumptionDetail.items;
                        let employeeProfile        =   consumptionDetail.employee;
                      
                        setTimeout(() => {
                            this.setState({
                                consumptionDetail       :   pr.location && pr.location.state && pr.location.state.clone ? null : consumptionDetail,
                                consumptionForm         :   {
                                    ...this.initConsumptionForm,
                                    transaction_id          :   pr.location && pr.location.state && pr.location.state.clone ?  '' : consumptionDetail?.transaction_id, 
                                    transaction_date        :   pr.location && pr.location.state && pr.location.state.clone ?  moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') : consumptionDetail.transaction_date,
                                    warehouse_id            :   consumptionDetail.warehouse_id,
                                    consumption_type        :   consumptionDetail.consumption_type ? consumptionDetail.consumption_type : 'asset',
                                    asset_id                :   consumptionDetail.assetProfile ? consumptionDetail.assetProfile.asset_id : '', 
                                    workstation_id          :   consumptionDetail.workstation ? consumptionDetail.workstation.id : '',
                                    voucher_number          :   consumptionDetail.voucher_number,
                                    voucher_date            :   consumptionDetail.voucher_date ? consumptionDetail.voucher_date : '',
                                    notes                   :   consumptionDetail.notes,
                                    status                  :   consumptionDetail.status,
                                    adjustment_amount       :   consumptionDetail.adjustment_amount,
                                    ticket_id               :   consumptionDetail.ticket ? consumptionDetail.ticket.activity_id : '',
                                    sub_assembly_id         :   consumptionDetail.subAssembly ? consumptionDetail.subAssembly.enc_id :''
                                },
                                
                                ticketFromConsumption        :   consumptionDetail.ticket && consumptionDetail.consumption_type == "asset" ? true : false,
                                selectedTicket              :   consumptionDetail.ticket ? {...consumptionDetail.ticket, value: consumptionDetail.ticket.activity_id, label: `${consumptionDetail.ticket.activity_id} : ${consumptionDetail.ticket.header}`, display_label: `${consumptionDetail.ticket.activity_id} : ${consumptionDetail.ticket.header}` } : null,
                               searchedEmployee :               employeeProfile ?  {value : employeeProfile.enc_id, label : `${employeeProfile.display_full_name}`,employee : employeeProfile } : null,
                               
                               searchSubassembly : consumptionDetail.subAssembly && {
                                            label               :   consumptionDetail.subAssembly.name,
                                            value               :   consumptionDetail.subAssembly.enc_id,
                                            display_label       :   consumptionDetail.subAssembly.name,
                                            subAssembly         :   consumptionDetail.subAssembly
                                }
                            }, () => {
                                this.getWarehouseDetail(consumptionDetail.warehouse_id, true);
                                let assetProfile        =   consumptionDetail.assetProfile;
                                if(assetProfile) {
                                    this.setState({selectedAsset : {...assetProfile, label : `${assetProfile.name} (${assetProfile.asset_code})` }});
                                }
                                 // let employeeProfile        =   consumptionDetail.employee;
                                // if(employeeProfile) {
                                //     this.setState({searchedEmployee : {value : employeeProfile.enc_id, label : `${employeeProfile.display_full_name}`,employee : employeeProfile }});
                                // }
    
                                let workstation = consumptionDetail.workstation;
                                if (workstation) {
                                    this.setState({ searchedWorkstation: { value: workstation.id, label: workstation.workstation_name } });
                                }
                                
                               
                                consumedItems.forEach((consumedItem, key) => {
                                   
                                    let pre_selected_stock = {batch_number : consumedItem.batch_number, expiry_date : consumedItem.expiry_date ,demand_transaction_id : consumedItem.demand_transaction_id,demand_item_id:consumedItem.demand_item_id, qty : consumedItem.qty, consumption_item_id : consumedItem.consumption_item_id,allocation_item_id:consumedItem.allocation_item_id,allocation_data : consumedItem.allocation_item_id ? consumedItem : null};
                                    this.onItemSelect({...consumedItem , itemdata : {...consumedItem.item_profile,isAddRemark : consumedItem.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate, pre_selected_stock,false);
                                });
                            });
                            this.addNewItemRow()
                        },500)
                       
                    }

                    /********************************ADD CONSUMPTION FROM PURCHASE************************************* */
                    if(pr.location && pr.location.state && pr.location.state.addConsumptionFromPurchase) {
                        this.setState({buttonDisabled: true})
                        let consumptionDetail       =   pr.location.state.addConsumptionFromPurchase;
                       
                        let consumedItems           =   consumptionDetail.items;
                        
                        setTimeout(() => {
                            this.setState({
                                consumptionDetail       :   null,
                                consumptionForm         :   {
                                    ...this.initConsumptionForm,
                                    transaction_date        :    moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') ,
                                    warehouse_id            :   consumptionDetail.warehouse_id,
                                   
                                },
                                addConsumptionFromPurchase :   true,
                                buttonDisabled               :  true,
                            }, () => {
                                this.getWarehouseDetail(consumptionDetail.warehouse_id, true);
                                
                                consumedItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({...consumedItem , itemdata : {...consumedItem.item,isAddRemark : consumedItem.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate,null,false);
                                });
                            });
                            this.addNewItemRow()
                        },500)
                        
                    }

                    /********************************ADD CONSUMPTION FROM PURCHASE REPORT************************************* */
                    if(pr.location && pr.location.state && pr.location.state.addConsumptionFromPurchaseReport) {
                        this.setState({buttonDisabled: true})
                        let consumptionDetail       =   pr.location.state.addConsumptionFromPurchaseReport;
                       
                        let consumedItems           =   consumptionDetail.items;

                        setTimeout(() => {
                            this.setState({
                                consumptionDetail       :   null,
                                consumptionForm         :   {
                                    ...this.initConsumptionForm,
                                    transaction_date        :    moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') ,
                                    warehouse_id            :   consumptionDetail.warehouse_id,
                                   
                                },
                                addConsumptionFromPurchase :   true,
                                buttonDisabled               :  true,
                            }, () => {
                                this.getWarehouseDetail(consumptionDetail.warehouse_id, true);
                                
                                consumedItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({...consumedItem , itemdata : {...consumedItem,isAddRemark : consumedItem?.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate,null,false);
                                });
                            });
                        },500)
                       
                    }

                    /********************************ADD CONSUMPTION FROM ALLOCATION************************************* */
                    if(pr.location && pr.location.state && pr.location.state.addConsumptionFromAllocation) {
                        this.setState({buttonDisabled: true})
                        let consumptionDetail       =   pr.location.state.addConsumptionFromAllocation;
                        let consumedItems           =   consumptionDetail.items;
                        
                        
                        setTimeout(() => {
                            this.setState({
                                consumptionDetail       :   null,
                                consumptionForm         :   {
                                    ...this.initConsumptionForm,
                                    transaction_date        :   pr.location && pr.location.state && pr.location.state.clone ?  moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') : consumptionDetail.transaction_date,
                                    warehouse_id            :   consumptionDetail.warehouse_id,
                                    employee_id             :   consumptionDetail.employee ? consumptionDetail.employee.enc_id : '',
                                    consumption_type        :   consumptionDetail.consumption_type ? consumptionDetail.consumption_type : consumptionDetail.demand_for ? consumptionDetail.demand_for : 'asset',
                                    asset_id                :   consumptionDetail.assetProfile ? consumptionDetail.assetProfile.asset_id : consumptionDetail.asset ? consumptionDetail.asset.asset_id : '', 
                                    workstation_id          :   consumptionDetail.workstation ? consumptionDetail.workstation.id : '',
                                    ticket_id               :   consumptionDetail.ticket ? consumptionDetail.ticket.activity_id : '',
                                    sub_assembly_id         :   consumptionDetail.subAssembly ? consumptionDetail.subAssembly.enc_id : consumptionDetail.sub_assembly ? consumptionDetail.sub_assembly.enc_id : ''
                                },
                                addConsumptionFromPurchase  :   true,
                                buttonDisabled               :  true,
                                ticketFromConsumption        :   consumptionDetail.ticket && consumptionDetail.consumption_type == "asset" ? true : false,
                                ticketLinkedFromAllocation   :   consumptionDetail.ticket ? true : false,
                                selectedTicket              :   consumptionDetail.ticket ? {...consumptionDetail.ticket, value: consumptionDetail.ticket.activity_id, label: `${consumptionDetail.ticket.activity_id} : ${consumptionDetail.ticket.header}`, display_label: `${consumptionDetail.ticket.activity_id} : ${consumptionDetail.ticket.header}` } : null,
                            }, () => {
                                this.getWarehouseDetail(consumptionDetail.warehouse_id, true);
                                
                                let assetProfile        =   consumptionDetail.assetProfile ? consumptionDetail.assetProfile : consumptionDetail.asset ? consumptionDetail.asset : null;
                                if(assetProfile) {
                                    this.setState({selectedAsset : {...assetProfile, label : `${assetProfile.name} (${assetProfile.asset_code})` }});
                                }

                                let employeeProfile        =   consumptionDetail.employee;
                                if(employeeProfile) {
                                    this.setState({searchedEmployee : {value : employeeProfile.enc_id, label : `${employeeProfile.display_full_name}`,employee : employeeProfile }});
                                }
    
                                let workstation = consumptionDetail.workstation;
                                if (workstation) {
                                    this.setState({ searchedWorkstation: { value: workstation.id, label: workstation.workstation_name } });
                                }

                                let subAssembly         =   consumptionDetail.subAssembly ? consumptionDetail.subAssembly : consumptionDetail.sub_assembly ? consumptionDetail.sub_assembly : null;
                                if(subAssembly) {
                                    this.setState({
                                        searchSubassembly : {
                                            label               :   subAssembly.name,
                                            value               :   subAssembly.enc_id,
                                            display_label       :   subAssembly.name,
                                            subAssembly         :   subAssembly
                                        }
                                    })
                                }
                                
                                setTimeout(() => {
                                         consumedItems.forEach((consumedItem, key) => {
                                   let itemData     =   consumedItem.itemData ? consumedItem.itemData : consumedItem.item_profile ? consumedItem.item_profile : null;
                                    let pre_selected_stock = {batch_number : consumedItem?.batch_number, expiry_date : consumedItem?.expiry_date , qty : consumedItem?.pending_qty, allocation_item_id:consumedItem?.allocation_item_id};
                                    this.onItemSelect({...consumedItem , itemdata : {...itemData ,isAddRemark : consumedItem.remark ? true : false}}, key ,consumedItem.pending_qty, consumedItem.rate, pre_selected_stock,false);
                                });
                                }, 1000);
                               
                               
                            });
                            this.addNewItemRow()
                            
                        },500)
                       
                    }

                    if(pr.location && pr.location.state && pr.location.state.addConsumptionFromInventoryTransfer) {
                        this.setState({buttonDisabled: true})
                        let consumptionDetail       =   pr.location.state.addConsumptionFromInventoryTransfer;
                       
                        let consumedItems           =   consumptionDetail.items;
                        
                        setTimeout(() => {
                            this.setState({
                                consumptionDetail       :   null,
                                consumptionForm         :   {
                                    ...this.initConsumptionForm,
                                    transaction_date        :    moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') ,
                                    warehouse_id            :   consumptionDetail.warehouse_id,
                                   
                                },
                                only_stocked_item        :   'N',
                                buttonDisabled               :  true,
                                addConsumptionFromPurchase :   true
                            }, () => {
                                this.getWarehouseDetail(consumptionDetail.warehouse_id, true);
                                
                                consumedItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({...consumedItem , itemdata : {...consumedItem.item,isAddRemark : consumedItem.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate,null,false);
                                });
                            });
                            this.addNewItemRow()
                        },500)
                        
                    }
                     
                    if(ParamObject.get('ticketId')) {
                        const storedData = localStorage.getItem('selectedTicketToAddconsumption');

                        if (storedData) {
                            const { asset, site_id, ticket_header, ticketFor, ticketForData } = JSON.parse(storedData);
                            let ticket_id = ParamObject.get('ticketId')

                            this.setState({
                                ticketFromTicket        :   true,
                                disableForTicket        :   ticketFor === 'site' ? false : true,
                                ticketSelectedSite      :   site_id ? site_id : '',
                                ticketSelectedAsset     :   ticketFor === 'asset' ? ticketForData : null,
                                selectedAsset           :   ticketFor === 'asset' ? { ...ticketForData, label: `${ticketForData.name} (${ticketForData.asset_code})` } : null,
                                consumptionForm          :   {
                                    ...this.initConsumptionForm,
                                    transaction_date        :   moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') ,
                                    consumption_type    :   ticketFor === 'site' ? 'asset' : ticketFor,
                                    asset_id            :   ticketForData && ticketFor === 'asset' ? ticketForData.asset_id : "",
                                    ticket_id           : ticket_id,
                                    sub_assembly_id     :   ticketForData && ticketFor === 'sub_assembly' ? ticketForData.enc_id : "",
                                    workstation_id      :   ticketForData && ticketFor === 'workstation' ? ticketForData.id : "",
                                    employee_id         :   ticketForData && ticketFor === 'employee' ? ticketForData.enc_id : "",
                                },

                                searchSubassembly       :   ticketFor === 'sub_assembly' ? {
                                    label                   :   ticketForData.name,
                                    value                   :   ticketForData.enc_id,
                                    display_label           :   ticketForData.name,
                                    subAssembly             :   ticketForData
                                } : null,
                                searchedEmployee        :   ticketFor === 'employee' ? {
                                    value : ticketForData.enc_id, label : ticketForData.display_full_name, employee : ticketForData
                                } : null, 

                                ticketFromConsumption    :   ticket_id ? true : false,
                                //addConsumptionFromPurchase :   true,
                                allWarehouses           :   this.state.allWarehouses.filter(wd => wd.site_id.includes(site_id)),
                                selectedTicket          :   ticket_header ? {asset_id : asset ? asset.asset_id : '', value: ticket_id, label: `${ticket_id} : ${ticket_header}`, display_label: `${ticket_id} : ${ticket_header}` } : null
                            }, () => {
                                let workstation        =   ticketForData && ticketFor === 'workstation' ? ticketForData : null;
                                if(workstation) {
                                    this.setState({searchedWorkstation : {value:workstation.id,label:workstation.workstation_name }});
                                }
                            })
                        } 
                      
                    } 
                });
            });
        }
    }
    
   
    onWarehouseChange                   =   (selectedOption) =>  {
        if(!this.state.selectedTicket){
         this.setState({
             selectedAsset               :   null,
             searchedWorkstation         :   null,
             searchedEmployee            :   null,
             searchSubassembly           :    null,
             warehouseSites              :   [],
             
             consumptionForm             :   {
                 ...this.state.consumptionForm,
                 workstation_id          :   '',
                 asset_id                :   '',
                 employee_id             :   '',
                 tag_users           :       [],
                 sub_assembly_id         :   ''
             },
             // storeIndentItemRows         :   [...this.iniitstoreIndentItemRows]
         }, () => {
            
         });
        }
        this.getWarehouseDetail(selectedOption ? selectedOption.value : null);
     }
    
    getWarehouseDetail                  =   (warehouse_id, editConsumption=false)  =>  {
        if (warehouse_id) {
            this.setState({warehouseDetailLoading : true, warehouseDetail : null, warehouseSites : []});
            HttpAPICall.withAthorization('GET', this.warehouseUrl + '/' + warehouse_id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.setState({
                    warehouseDetail         :   respData.data,
                    warehouseSites          :   respData.data.sites
                });
                if(!editConsumption) {
                    
                }
            }).then(() => {
                this.setState({warehouseDetailLoading : false});
            });
        } else {
            this.setState({warehouseDetail: null})
        }
    }
    
    onItemSelect                        =   (selectedOption, key, qty = 1, consumption_price = null , pre_selected_stock =  null,addRow = true)  =>  {

        if(selectedOption) {
                if(!this.state.consumptionForm.warehouse_id){
                    toast.error('Please select  Warehouse First', { position: toast.POSITION.TOP_RIGHT })
                }else if(!this.state.consumptionForm.consumption_type && this.state.addConsumptionFromPurchase == false){
                    toast.error('Please select  Consumption For ', { position: toast.POSITION.TOP_RIGHT })
                }else if(!this.state.consumptionForm.asset_id && !this.state.consumptionForm.workstation_id && !this.state.searchedEmployee && !this.state.consumptionForm.sub_assembly_id && this.state.addConsumptionFromPurchase == false){
                    toast.error('Please select Asset/Workstation/Employee/Sub Assembly First ', { position: toast.POSITION.TOP_RIGHT })
                }else{
                   let consumptionItemRows         =   this.state.consumptionItemRows;
                
                    let itemdata                    =   selectedOption.itemdata;
                    if(!consumption_price) {
                        consumption_price           =   itemdata && itemdata.consumption_price ? itemdata.consumption_price : 0;
                    }
                    let row                         =   {...consumptionItemRows[key], item : itemdata , qty : qty , rate : consumption_price,remark : selectedOption.remark ? selectedOption.remark : "",consumedData:selectedOption};
                    consumptionItemRows[key]            =   row;
                    this.setState({consumptionItemRows : [...consumptionItemRows]}, () => {
                        
                        if(!this.state.consumptionItemRows.find(ir => !ir.item)) {
                            if(addRow == true){
                                setTimeout(() => {this.addNewItemRow()}, 1000);
                            }
                        }

                        if (itemdata && itemdata.category && itemdata.category === 'services') {
                            // For service items, mark API calls as completed directly
                            let consumptionItemRows = this.state.consumptionItemRows;
                            consumptionItemRows[key] = {
                                ...consumptionItemRows[key],
                                allApiCallsCompleted: true, // Mark as completed for service items
                            };
                            this.setState({ consumptionItemRows: [...consumptionItemRows] }, () => {
                                // Check button enablement
                                this.handleButtonEnable();
                            });
                        } else if (itemdata && itemdata.category !== 'services' || this.state.addConsumptionFromPurchase === true) {
                            // Call getItemInventoryLevel only for non-service items (goods)
                            this.getItemInventoryLevel(itemdata.id, key, pre_selected_stock, selectedOption);
                        }
                      
                    });
            }
        }
        
       
       
    }
    
    getItemInventoryLevel               =   (item_id, key, pre_selected_stock = null,selectedData=null)  =>  {
        
        this.setState({ buttonDisabled: true });
        let consumptionItemRows         =   this.state.consumptionItemRows;
        consumptionItemRows[key]        =   {...consumptionItemRows[key] , stock_loading : true,selected_allocation:null,selected_stock:null,allocation_list:[],current_stocks:[]};
        this.setState({consumptionItemRows : [...consumptionItemRows]});
        let allocationParams            =       {
            search_warehouse_id             :       this.state.consumptionForm.warehouse_id ,
            need_item_detail                :       "Y",
            search_item_ids                 :        [item_id],
            include_item_demands            :       "Y",
            search_asset_ids            :     this.state.consumptionForm.asset_id ?  [this.state.consumptionForm.asset_id] : [],
                    search_employee_ids         :      this.state.consumptionForm.employee_id ?  [this.state.consumptionForm.employee_id] : [],
                    search_workstation_ids      :      this.state.consumptionForm.workstation_id ? [this.state.consumptionForm.workstation_id] : [],
                    search_sub_assembly_ids     :      this.state.consumptionForm.sub_assembly_id ?  [this.state.consumptionForm.sub_assembly_id] : [],
        }
        const headers                         =   { 'Accept': 'application/json','Content-Type':'application/json','Authorization':'Bearer ' + this.props.access_token};
        Promise.all([
            axios({ method: 'GET', headers: headers, url: INVENTORY_API_BASE_URL_2 + '/item_current_stock/' + item_id,params :  {interchangeable_items_current_stock: "N", warehouse_id: this.state.consumptionForm.warehouse_id}}), 
            axios({ method: 'GET', headers: headers, url: INVENTORY_API_BASE_URL_2 + '/item_demand/pending-demand-and-allocation-item',params : {...allocationParams}}), 
        ])
        .then((results) => {
            
            let current_stock_list          =           results[0].data;
            let allocation_list             =           results[1].data.filter(al => al.transaction_type == "allocation" ? true : false).map(list => {return{value:list.allocation_item_id,label:list.allocation_transaction_id,allocation_detail:list}});
            let item_demand_list             =           results[1].data.filter(al => al.transaction_type == "item_demand" ? true : false).map(list => {return{value:list.demand_item_id,label:list.demand_transaction_id,demand_data:list}});
           
                                  
               
                let current_stocks              =   current_stock_list.current_stock.map(cs => {
                                                        return {
                                                            value : cs.qty,
                                                            label : (<Ax><b>Batch Number : </b> {cs.batch_number} <br />
                                                                <b>Expiry Date : </b> {cs.expiry_date_display}<br />
                                                                <b>Current Qty : </b> {cs.qty} <br/>
                                                                <b>Free Qty : </b> {cs.free_qty}</Ax>),
                                                            stock : cs,
                                                            display_label: `${cs.batch_number} & ${cs.expiry_date_display} `
                                                        }
                                                    });
                let consumptionItemRows         =   this.state.consumptionItemRows;
                let isDuplicate                 =   false;
                let updatedAllocationList       =   allocation_list;
               
                if(consumptionItemRows[key].consumedData && consumptionItemRows[key].consumedData.allocation_item_id && !allocation_list.find(al => al.value == consumptionItemRows[key].consumedData.allocation_item_id) ){
                    const newObject = {
                        value: consumptionItemRows[key].consumedData.allocation_item_id,
                        label: consumptionItemRows[key].consumedData.allocation_transaction_id,
                        allocation_detail: consumptionItemRows[key].consumedData  // Add the appropriate details
                        };

                        // // Check if newObject already exists in the list
                         isDuplicate = allocation_list.some(item =>
                        item.value === newObject.value && item.label === newObject.label
                        );

                        // // Add newObject if it doesn't already exist
                         updatedAllocationList = isDuplicate
                        ? allocation_list
                        : [...allocation_list, newObject];
                }

                let isDuplicateDemand                 =   false;
                let updatedDemandList       =   item_demand_list;
               
                if(consumptionItemRows[key].consumedData && consumptionItemRows[key].consumedData.demand_item_id && !item_demand_list.find(al => al.value == consumptionItemRows[key].consumedData.demand_item_id) ){
                    const newObject = {
                        value: consumptionItemRows[key].consumedData.demand_item_id,
                        label: consumptionItemRows[key].consumedData.demand_transaction_id,
                        demand_data: {...consumptionItemRows[key].consumedData,pending_qty : 0} ,
                        demand_pending_qty   :  consumptionItemRows[key].qty // Add the appropriate details
                        };

                        // // Check if newObject already exists in the list
                         isDuplicateDemand = item_demand_list.some(item =>
                        item.value === newObject.value && item.label === newObject.label
                        );

                        // // Add newObject if it doesn't already exist
                         updatedDemandList = isDuplicateDemand
                        ? item_demand_list
                        : [...item_demand_list, newObject];
                }
                 // // New object to add
                                                
                let selected_allocation         =   consumptionItemRows[key].consumedData && consumptionItemRows[key].consumedData.allocation_item_id ? updatedAllocationList.find(al => al.value == consumptionItemRows[key].consumedData.allocation_item_id) : null;
                   let demand_data       =    consumptionItemRows[key].consumedData &&  consumptionItemRows[key].consumedData.demand_item_id ? updatedDemandList.find(dl => dl.value == consumptionItemRows[key].consumedData.demand_item_id) : null;                              
                   
                   consumptionItemRows[key]        =   {
                        ...consumptionItemRows[key],
                        stock_loading           :       false,
                        current_stocks          :      current_stocks,
                        allocation_item_id      :       consumptionItemRows[key].consumedData && consumptionItemRows[key].consumedData.allocation_item_id ? consumptionItemRows[key].consumedData.allocation_item_id : '' ,
                        selected_allocation     :       selected_allocation ? {...selected_allocation,...selected_allocation?.allocation_detail,pending_qty : Number(selected_allocation?.allocation_detail?.pending_qty??0) + Number(consumptionItemRows[key].qty) } : null,
                        allApiCallsCompleted: true,
                        allocation_list : updatedAllocationList,
                       item_demand_list        : updatedDemandList,
                       consumption_item_id      :   pre_selected_stock && pre_selected_stock.consumption_item_id ? pre_selected_stock.consumption_item_id : '',
                       demand_pending_qty              :  demand_data && demand_data.demand_data && !selected_allocation ? Number(demand_data.demand_data.pending_qty) + Number(consumptionItemRows[key].qty) : null,
                       demand_transaction_id          :   consumptionItemRows[key].consumedData &&  consumptionItemRows[key].consumedData.demand_transaction_id ? consumptionItemRows[key].consumedData.demand_transaction_id : '',
                       demand_item_id          :   consumptionItemRows[key].consumedData &&  consumptionItemRows[key].consumedData.demand_item_id ? consumptionItemRows[key].consumedData.demand_item_id : '', 
                };
                
                let selectedStock               =   current_stocks.length > 0 ? current_stocks[0] : null;
                let findSelectedStockKey        =   null;
               
                if(pre_selected_stock) {
                    let preSelectedStock            =   current_stocks.find(cs => {
                                                            return cs.stock.batch_number == pre_selected_stock.batch_number 
                                                                        && cs.stock.expiry_date == pre_selected_stock.expiry_date;
                                                      });
                    if(!preSelectedStock) {
                        
                        selectedStock           =  {
                            consumption_item_id                :   pre_selected_stock.consumption_item_id,
                            batch_number            :   pre_selected_stock.batch_number,
                            expiry_date             :   pre_selected_stock.expiry_date
                        };
                    } else {
                        
                        let cs = preSelectedStock.stock;
                        let qty             =   cs.qty;
                        let free_qty        =   this.props.location && this.props.location.state && this.props.location.state.clone ? cs.free_qty :  cs.free_qty + pre_selected_stock.qty;
                        
                        
                        selectedStock           =   {...preSelectedStock , consumption_item_id : pre_selected_stock.consumption_item_id, stock : {
                                    ...cs,
                                    qty         :   qty,
                                    free_qty    :   free_qty
                            }};
                    }
                }
                if((consumptionItemRows[key].selected_allocation && consumptionItemRows[key].selected_allocation.allocation_item_id)){
                    selectedStock = null;
                }
                
                this.setState({consumptionItemRows : [...consumptionItemRows]} , () => {
                    
                    this.onBatchChange(selectedStock, key);
                    this.handleButtonEnable()
                });
           // }
          
            
        })
     
    }

    // handleButtonEnable = () => {
    //     const allApiCallsCompleted = this.state.consumptionItemRows.filter(i => i.item ? true : false).every(item => item.allApiCallsCompleted);
    //     this.setState({ buttonDisabled: !allApiCallsCompleted });
    // };

    handleButtonEnable = () => {
        // Check if all items are either processed (allApiCallsCompleted) or are services
        const allItemsProcessed = this.state.consumptionItemRows
            .filter(i => i.item) // Ensure the item exists
            .every(item => 
                (item.allApiCallsCompleted || (item.item && item.item.category === 'services'))
            );
        
        // Enable the button only if all items are processed or are services
        this.setState({ buttonDisabled: !allItemsProcessed });
    };
    
    onBatchChange                       =   (selectedOption, key)  =>  {
      
        if(selectedOption && selectedOption.stock) {
            let selected_stock              =   selectedOption.stock;
            let consumptionItemRows         =   this.state.consumptionItemRows;
            consumptionItemRows[key]        =   {
                ...consumptionItemRows[key], 
                selected_stock  :   selected_stock,
                stock_qty       :   selected_stock.qty,
                free_qty        :   selected_stock.free_qty,
                consumption_item_id        :   selectedOption.consumption_item_id ? selectedOption.consumption_item_id : null,
            };
            this.setState({consumptionItemRows : [...consumptionItemRows]});
        }
    }

    onAllocationChange          =       (selectedOption,key,qty=0)        =>      {
        
        if(selectedOption && selectedOption.allocation_detail) {
            let allocation_detail              =   selectedOption.allocation_detail;
            let consumptionItemRows         =   this.state.consumptionItemRows;
            consumptionItemRows[key]        =   {
                ...consumptionItemRows[key], 
                selected_allocation  :   {...allocation_detail,allocation_detail:{...allocation_detail },pending_qty : this.state.consumptionDetail && allocation_detail && !allocation_detail.pending_qty ? Number(qty) : Number(allocation_detail.pending_qty)},
                qty                  :   selectedOption.qty ? Number(selectedOption.qty) : this.state.consumptionDetail  && allocation_detail && !allocation_detail.pending_qty ? Number(qty) : Number(allocation_detail.pending_qty),
                consumption_item_id  :   selectedOption.consumption_item_id ? selectedOption.consumption_item_id : null,
                selected_stock       :   {...allocation_detail},
                allocation_item_id   :   allocation_detail ? allocation_detail.allocation_transaction_id : ''
            };
            this.setState({consumptionItemRows : [...consumptionItemRows]});
        }else{
            let consumptionItemRows         =   this.state.consumptionItemRows;
            consumptionItemRows[key]        =   {
                ...consumptionItemRows[key], 
                selected_allocation    :   {allocation_item_id : null,batch_number:null},
                qty                  :   1,
                consumption_item_id  :   null,
                selected_stock       :   consumptionItemRows.current_stocks && consumptionItemRows.current_stocks.length > 0 ? consumptionItemRows.current_stocks[0].stock : null,
                allocation_item_id      :  null
            };
            this.setState({consumptionItemRows : [...consumptionItemRows]});
        }
    }
    
    itemViewModalInit                       =   (data,k)  =>  {
        this.setState({ itemSearchedDetails        :    data,itemRowNumber : k})
    }

    viewConsumptionModalInit                       =   (data,k)  =>  {

       if(this.state.consumptionForm?.asset_id || this.state.consumptionForm?.workstation_id){
            if(data){
                this.setState({    
                    itemSearchedDetails   :    data,
                    itemRowNumber         :    k
                },() => this.consumptionHistoryRef.current.modalInit(data,this.state.consumptionForm.workstation_id,this.state.consumptionForm.asset_id,this.state.consumptionForm.consumption_type))   
            }
       }else{
            toast.error('Please Select Asset/Workstation first', { position: toast.POSITION.TOP_RIGHT });
       }
       
    }

    addRemark       =   (data,k)      =>      {
        let allRows                 =   this.state.consumptionItemRows;
        let itemdata = {...data,isAddRemark : true}
        allRows[k]                  =   {...this.state.consumptionItemRows[k] , item  : itemdata};
        this.setState({consumptionItemRows : allRows});
    }

     //********************EDIT ITEM DETAILS ******************************* */

     itemSearchModalInit             =       ()          =>      {
        this.setState(prevState => ({ showItemSearchModal: !prevState.showItemSearchModal }), () => {
            if (this.state.showItemSearchModal) {
                let allRows                 =   this.state.consumptionItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if (this.itemSearchRef.current) {
                    this.itemSearchRef.current.searchItemModalInit(item_ids);
                }
            }
        });
        
    }

    editItemModalInit          =       (data)           =>      {
        this.setState(prevState => ({ showItemEditModal: !prevState.showItemEditModal }), () => {
            if (this.state.showItemEditModal) {
                let allRows                 =   this.state.consumptionItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if(this.editItemModalRef.current){
                    this.editItemModalRef.current.itemEditModalInit(data); 
                }
                
            }
        });
        
    }

    addItemModalInit          =       ()           =>      {
        this.setState(prevState => ({ showItemAddModal: !prevState.showItemAddModal }), () => {
            if (this.state.showItemAddModal) {
                let allRows                 =   this.state.consumptionItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }

                if(this.addItemModalRef.current){
                    this.addItemModalRef.current.itemAddModalInit(); 
                }
            
            }
        });
        
    }

    submitEditItem          =       ()      =>      {
        HttpAPICall.withAthorization('GET',this.itemDetailUrl + '/' + this.state.itemSearchedDetails.id, this.props.access_token, null, null, (response) => {
           let selectedItem = {
                label               :   (<Ax>
                                            <b>Item : </b> {response.data.data.name} <small> ({response.data.data.item_code})</small><br/>
                                            <small>
                                                <b>Manufacturer : </b> {response.data.data.manufacturer ? response.data.data.manufacturer.name : ''}<br/>
                                                <b>MPN : </b> {response.data.data.manufacturer_part_no}</small></Ax>),
                value               :   response.data.data.id ,
                display_label       :   `${response.data.data.name} (${response.data.data.item_code})`,
                itemdata            :   response.data.data
            };
            this.setState({
                  itemSearchedDetails        :    response.data.data
               },() => {
                   this.onItemSelect(selectedItem,this.state.itemRowNumber,this.state.consumptionItemRows[this.state.itemRowNumber]?.qty)
               });
        })
       
    }
    
    addNewItemRow                       =   ()  =>  {
       
        this.setState({consumptionItemRows : [...this.state.consumptionItemRows, ...this.iniitConsumptionItemRows ] });
    }


    removeItemRow                       =   (k,allocation_item_id,demand_item_id) =>  {
        let consumptionItemRows             =   this.state.consumptionItemRows;
        if(consumptionItemRows.length > 1) {
            consumptionItemRows.splice(k, 1);
            this.setState({consumptionItemRows : consumptionItemRows});
        }
        if((allocation_item_id || demand_item_id) && this.state.allAllocatedItemIds && this.state.allAllocatedItemIds.length > 0){
           let allocation_ids =  this.state.allAllocatedItemIds.filter(id => (id.allocation_item_id !== allocation_item_id) && (id.demand_item_id !== demand_item_id));
           this.setState({
             allAllocatedItemIds     :   allocation_ids
           })
        }
    }

     //****************************CLOSE CONSUMPTION *********************/
    closeEvent              =           ()      =>      {
        if(this.state.consumptionDetail){
            this.props.history.push({pathname: `/consumption_list`, state: {consumptionData : this.state?.consumptionDetail }})
        }else{
            this.props.history.push({pathname: `/consumption_list`})
        }
    }

    addItemsfromSearch          =       (selectedItems)     =>  {
        let consumptionItemRows = selectedItems.map((pu_item, k) => {
            return {
                item                        :          {...pu_item.item,isAddRemark : pu_item.remark ? true : false},
                req_allocation_loading      :           true,
                req_allocation              :           [],
                id                          :           pu_item.item.id,
                qty                         :           pu_item.qty,
                rate                        :           pu_item.rate,
                remark                      :           '',
                gst                         :           pu_item.gst,
                discount_type               :           '',
                discount_rate               :           '',
                po_item_id                  :           '',
                req_item_id                 :           '',
                selected_allocation         :           null,
                expiry_date                 :           '',
                batch_number                :           '',
                consumption_item_id                    :           null,
            };
        });
        let allRows = this.state.consumptionItemRows ?  this.state.consumptionItemRows.filter(i => i.item ? true : false) : [];
        let consumedItems = allRows.concat(consumptionItemRows);
        consumedItems.forEach((consumedItem, key) => {
            this.onItemSelect({...consumedItem , itemdata : {...consumedItem.item,isAddRemark : consumedItem?.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate,false,false);
        });
        
    
    }

    pickAllocationItemModalInit          =       ()      =>      {
        if(document.getElementById("pickconsumptionForm")) {
            let form = document.getElementById("pickconsumptionForm")
            form.reset();
        }
        if (this.state.consumptionForm.warehouse_id && this.state.consumptionForm.consumption_type) {
            let consumption_type_id = '';
            if(this.state.consumptionForm.consumption_type == 'asset'){
                consumption_type_id = this.state.consumptionForm.asset_id;
            }else if(this.state.consumptionForm.consumption_type == 'employee'){
                consumption_type_id = this.state?.searchedEmployee?.value;
            }else if(this.state.consumptionForm.consumption_type == 'workstation'){
                consumption_type_id = this.state?.consumptionForm.workstation_id;
            }else if(this.state.consumptionForm.consumption_type == 'sub_assembly'){
                consumption_type_id = this.state?.consumptionForm.sub_assembly_id;
            }

            this.getAllPendingItemsfromAllocation(this.state.consumptionForm.warehouse_id, consumption_type_id)
          

        } else {
            toast.error('Please Select Warehouse First', { position: toast.POSITION.TOP_RIGHT })
        }
    }

    checkAllAllocationList                          =   ()  =>  {
        let allocationItemCheckboxCollections      =   document.getElementsByClassName('allocation_id_checkbox');
        if(allocationItemCheckboxCollections && allocationItemCheckboxCollections.length > 0) {
            for (let i = 0; i < allocationItemCheckboxCollections.length; i++) {
                allocationItemCheckboxCollections[i].checked = document.getElementById('all_check_allocation').checked;
            }
        }
    }

     //*****************************GET ALL PENDING ITEMS FROM PO************** */
     getAllPendingItemsfromAllocation        =       (warehouse_id,consumption_type_id = '',item_ids,selectedItem_ids,new_qty = false)        =>      {
        if(consumption_type_id){
            this.pickAllocationItemModal.show()
            // if(!this.state.pendingAllocationListLoaded){
                this.setState({pending_item_loading : true})
                let params                          =   {search_warehouse_id                   :       this.state.consumptionForm.warehouse_id ,
                                                        need_item_detail                :       "Y",
                                                       // search_warehouse_ids        :      [this.state.consumptionForm.warehouse_id],
                                                search_asset_ids            :     this.state.consumptionForm.asset_id ?  [this.state.consumptionForm.asset_id] : [],
                                                search_employee_ids         :      this.state.consumptionForm.employee_id ?  [this.state.consumptionForm.employee_id] : [],
                                                search_workstation_ids      :      this.state.consumptionForm.workstation_id ? [this.state.consumptionForm.workstation_id] : [],
                                                search_sub_assembly_ids     :      this.state.consumptionForm.sub_assembly_id ?  [this.state.consumptionForm.sub_assembly_id] : [],
                                                        item_ids                        :       item_ids && item_ids.length > 0 ? item_ids : [],
                                                        min_pending_qty                 :        0.01 ,
                                                        for_report                      :       'N',
                                                        include_item_demands            :   this.state.include_item_demands,
                                                    };
    
                HttpAPICall.withAthorization('GET',  INVENTORY_API_BASE_URL_2 + '/item_demand/pending-demand-and-allocation-item', this.props.access_token, params, {}, (resp) => {
                     
                    this.setState({
                        allPendingAllocationList      :     resp.data.map(d => {return({...d,new_qty :  d.pending_qty  })}),
                        pendingAllocationListLoaded   :       true
                    })
                }).then(() => {
                    this.setState({pending_item_loading : false});
                });
            // }
        }else{
            toast.error('Please Select Asset/Workstation/Employee', { position: toast.POSITION.TOP_RIGHT }) 
        }
       
    }

    submitSelectedAllocationItem        =       (e)         =>      {
        e.preventDefault();
         
        let  allocationItemCheckboxCollections      =    document.getElementsByClassName('allocation_id_checkbox');
        let allSelectedItems                =    [];
        let allQty                          =    [];

        if( allocationItemCheckboxCollections &&  allocationItemCheckboxCollections.length > 0) {
            for (let i = 0; i <  allocationItemCheckboxCollections.length; i++) {
               
                if( allocationItemCheckboxCollections[i].checked) {
                    // allocationItemCheckboxCollections[i].disabled = true;
                    let allocation_item_id    =       allocationItemCheckboxCollections[i].value;
                    let allocation_id = allocation_item_id ? allocation_item_id : null;
                    let demand_item_id    =       allocationItemCheckboxCollections[i].getAttribute("data-demandId");
                    let item_id    =       allocationItemCheckboxCollections[i].getAttribute("data-itemId");
                    let demand_transaction_id    =       allocationItemCheckboxCollections[i].getAttribute("data-demandTransactionId");
                    let allocation_transaction_id    =       allocationItemCheckboxCollections[i].getAttribute("data-allocationTransactionId");
                    let selectedAllocationItem = this.state.allPendingAllocationList.find((po) =>
                       (((po.allocation_item_id == allocation_id) && (po.demand_item_id == demand_item_id)))
                      );  
                    allQty.push(allocationItemCheckboxCollections[i].getAttribute("data-qty"));
                    allSelectedItems.push(selectedAllocationItem)
                   
                   
                }
            }
        }

   
       //***************************HANDLE SELECTED ITEM FROM PO  **********************/
        if (allSelectedItems.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT })
        }else{
            let checkBlankQty = allQty.filter(function (item) {
                return item == "" || item == null
            }).length == 0;
            if (checkBlankQty == false) {
                toast.error('Please fill the allocated Qty', { position: toast.POSITION.TOP_RIGHT })
            } else {
                //allSelectedItems.forEach((list,key) => this.getItemInventoryLevel(list.item_id,key,null,list))
                this.handleSelectedItemsToAddConsumption(allSelectedItems)

            }   
            
        }     
       
    }

    handleSelectedItemsToAddConsumption       =       (allItems)      =>      {
        this.setState({savePickItemSubmitting : true})
        
         allItems.forEach((list, key) => {
          
             let consumptionItemRows            =           this.state.consumptionItemRows;
             let itemkey                     =           this.state.consumptionItemRows.length > 1? this.state.consumptionItemRows.length + key : key;
             
             let allocationParams            =       {
                search_warehouse_id             :       this.state.consumptionForm.warehouse_id ,
                need_item_detail                :       "Y",
                include_item_demands            :       'Y',
                search_item_ids                 :        [list.item_id],
                search_asset_ids            :     this.state.consumptionForm.asset_id ?  [this.state.consumptionForm.asset_id] : [],
                    search_employee_ids         :      this.state.consumptionForm.employee_id ?  [this.state.consumptionForm.employee_id] : [],
                    search_workstation_ids      :      this.state.consumptionForm.workstation_id ? [this.state.consumptionForm.workstation_id] : [],
                    search_sub_assembly_ids     :      this.state.consumptionForm.sub_assembly_id ?  [this.state.consumptionForm.sub_assembly_id] : [],
                                                
            }

            const headers                         =   { 'Accept': 'application/json','Content-Type':'application/json','Authorization':'Bearer ' + this.props.access_token};
            Promise.all([
                axios({ method: 'GET', headers: headers, url: TapApiUrls.ITEM +  '/' + list.item_id }), 
                axios({ method: 'GET', headers: headers, url:INVENTORY_API_BASE_URL_2 + '/item_demand/pending-demand-and-allocation-item',params : {...allocationParams}}), 
                axios({ method: 'GET', headers: headers, url: INVENTORY_API_BASE_URL_2 + '/item_current_stock/' + list.item_id,params :  {interchangeable_items_current_stock: "N", warehouse_id: this.state.consumptionForm.warehouse_id}}), 
            ])
            .then((results) => {
                
                let itemData = results[0].data.data;
                let allocation_list             =           results[1].data.filter(al => al.allocation_item_id ? true : false).map(list => {return{value:list.allocation_item_id,label:list.allocation_transaction_id,allocation_detail:list}});
                let item_demand_list            =           results[1].data.filter(al => al.demand_item_id ? true : false).map(list => {return{value:list.demand_item_id,label:list.demand_transaction_id,demand_data:list}});
                let current_stock_list          =           results[2].data;
               
                let selected_allocation           =  allocation_list && allocation_list.length > 0 ? allocation_list.find(cs => {  return cs.value == list.allocation_item_id  }) : null;
                   let selected_allocation_detail    =   selected_allocation && selected_allocation.allocation_detail ? selected_allocation.allocation_detail : null
                   let preSelectedStock            =   null;
                   let current_stocks              =   current_stock_list.current_stock.map(cs => {
                    return {
                        value : cs.qty,
                        label : (<Ax><b>Batch Number : </b> {cs.batch_number} <br />
                            <b>Expiry Date : </b> {cs.expiry_date_display}<br />
                            <b>Current Qty : </b> {cs.qty} <br/>
                            <b>Free Qty : </b> {cs.free_qty}</Ax>),
                        stock : cs,
                        display_label: `${cs.batch_number} & ${cs.expiry_date_display} `
                    }
                });
                  
                   consumptionItemRows[itemkey]        =   {
                           ...consumptionItemRows[itemkey],
                           stock_loading           :       false,
                           item                    :       itemData,
                           allocation_list         :       allocation_list,
                           qty                     :       list.new_qty,
                           allocation_item_id      :       list.allocation_item_id,
                           demand_item_id           :      list.demand_item_id,
                           item_demand_list         :      item_demand_list,
                           demand_pending_qty       :      list.pending_qty,
                           current_stocks           :      current_stocks && current_stocks.length > 0 ? current_stocks : [],
                           selected_allocation     :       {...selected_allocation,allocation_item_id:selected_allocation_detail?.allocation_item_id,pending_qty:selected_allocation_detail?.pending_qty,batch_number:selected_allocation_detail?.batch_number,expiry_date:selected_allocation_detail?.expiry_date,expiry_date_display:selected_allocation_detail?.expiry_date_display},
                           rate                    :       itemData.consumption_price ? itemData.consumption_price : 0
                   };
                   
                  
                
                   preSelectedStock = {...selected_allocation,allocation_detail:{...selected_allocation?.allocation_detail,pending_qty :   Number(selected_allocation?.allocation_detail?.pending_qty) }}
               
               
                   let allAllocatedItemIds = consumptionItemRows.map((item) => {
                       return { allocation_item_id: item.allocation_item_id , demand_item_id : item.demand_item_id};
                   });
                   
                   let newConsumptionItemRows = consumptionItemRows.filter(i => i.item ? true : false);
                   
                   this.setState({
                       consumptionItemRows: [...newConsumptionItemRows],
                       allAllocatedItemIds: allAllocatedItemIds
                   }, () => {
                       this.pickAllocationItemModal.hide();
                   });
                   
                  
            }).then(() => {})
             
         })
         this.setState({savePickItemSubmitting : false})
         this.addNewItemRow()
     }

     onDemandChange                       =   (selectedOption, key,qty)  =>  {
        
        if(selectedOption && selectedOption.demand_data) {
            
            let selected_demand_data              =   selectedOption.demand_data;
           
            let consumptionItemRows         =   this.state.consumptionItemRows;
            consumptionItemRows[key]        =   {
                ...consumptionItemRows[key], 
                selected_demand_data           :   selected_demand_data,
                demand_pending_qty             :  this.state.consumptionDetail && selected_demand_data.pending_qty == 0 ? qty :  selected_demand_data.pending_qty,
                demand_transaction_id   :   selected_demand_data.transaction_id,
                demand_item_id          :   selected_demand_data.demand_item_id,
               // allocation_item_id      :   selected_demand_data.allocation_item_id ? selected_demand_data.allocation_item_id : null
            };
            this.setState({consumptionItemRows : [...consumptionItemRows]});
        }else{
            let consumptionItemRows         =   this.state.consumptionItemRows;
            consumptionItemRows[key]        =   {
                ...consumptionItemRows[key], 
                selected_demand_data           :   null,
                demand_pending_qty             :   '',
                demand_transaction_id   :   '',
                demand_item_id          :   '',
                consumption_item_id     :   ''
                //allocation_item_id      :   null
            };
            this.setState({consumptionItemRows : [...consumptionItemRows]}); 
        }
    }
    
    consumptionFormSubmitHandler        =   (event, status = 0) =>  {
        event.preventDefault();
        let showErrors                  =   [];
        let formData                        =   {...this.state.consumptionForm, status : status, 
            employee_id                    :    this.state.consumptionForm.consumption_type == "employee" && this.state.searchedEmployee ? this.state.searchedEmployee.value : ''};
       
        let items                           =   this.state.consumptionItemRows.filter(ir => ir.item).map(i => {
                                                
                let batch_number = '',expiry_date='',free_qty=i.qty;
                if(i.selected_stock){
                    batch_number =  i.selected_stock.batch_number;
                    expiry_date  =   i.selected_stock.expiry_date;
                    free_qty     =   i.free_qty;
                }else if(i.selected_allocation){
                    batch_number =  i.selected_allocation.batch_number;
                    expiry_date  =   i.selected_allocation.expiry_date;
                    free_qty     =   i.selected_allocation.pending_qty;
                }
                let allocation_item_id = i.selected_allocation && i.selected_allocation.allocation_item_id ? i.selected_allocation.allocation_item_id : '';
                return {
                    id                  :   i.item.id,
                    batch_number        :   batch_number,
                    expiry_date         :   expiry_date,
                    consumption_item_id            :   i.consumption_item_id ? i.consumption_item_id : '',
                    allocation_item_id  :   i.selected_allocation ? i.selected_allocation.allocation_item_id : '',
                    qty                 :   i.qty,
                    free_qty            :   i.item.category !== "services" && (!i.demand_item_id || !allocation_item_id) ? free_qty : i.qty,
                    rate                :   i.rate,
                    remark              :   i.remark,
                    demand_item_id      :   i.demand_item_id ? i.demand_item_id : '',
                    pending_qty         :   i.selected_allocation ? i.selected_allocation.pending_qty : i.qty,
                        allocation_exists  :   i.allocation_list && i.allocation_list.length > 0 && !i.selected_allocation ? true  : false
                };
            });

            
             let allItemLists                    =            [];
             let dublicateItemExists             =            false;                                                  
             if(items.length > 0){
                 items.forEach(item => {
 
                     let combinationKey          =           item.id  + item.batch_number + item.expiry_date + item.allocation_item_id + item.demand_item_id;
                     if (allItemLists.includes(combinationKey)) {
                         dublicateItemExists = true;
                     }
                     return allItemLists.push(combinationKey); 
                 })
             }   
              
            
        if(formData.transaction_date == '') {
            showErrors                      =   [...showErrors , 'Please enter Transaction Date.'];
        }
        
        if(formData.warehouse_id == '') {
            showErrors                      =   [...showErrors , 'Please select Warehouse.'];
        }
        
        if(formData.consumption_type == '') {
            showErrors                      =   [...showErrors , 'Please select consumption for.'];
        }
        
        if(formData.consumption_type == 'asset') {
            if(formData.asset_id == '') {
                showErrors                      =   [...showErrors , 'Please select Asset.'];
            }
        } else if(formData.consumption_type == 'workstation') {
            if(formData.workstation_id == '') {
                showErrors                      =   [...showErrors , 'Please select Workstation.'];
            }
        }

        if(formData.status == '') {
            showErrors                      =   [...showErrors , 'Please select Status'];
        }
        
        if(items.length == 0) {
            showErrors                      =   [...showErrors , 'Please select Item and their consumption qty.'];
        }else if(dublicateItemExists == true) {
            showErrors                      =   [...showErrors , 'Consumption should not contain duplicate Item'];
        } else if(items.find(i => parseFloat(i.qty) <= 0)) {
            showErrors                      =   [...showErrors , 'Every Item\'s Consumption qty must be more than 0.'];
        }else if(items.find(i => i.qty == '')) {
            showErrors                      =   [...showErrors , 'Every Item\'s Consumption qty should not be blank'];
        }else if(this.state.negative_stock == 'N') {
          
            if(items.find(i => i.qty > i.free_qty)) {
                showErrors                      =   [...showErrors , 'Consumption qty can not more than Free Qty/Pending Qty.'];
            }
        }
        
        if(showErrors.length > 0) {
            toast.error(showErrors[0], {position: toast.POSITION.TOP_RIGHT});
        } else {
            formData                        =   {
                ...formData , 
                items                          :   items,
                update_item_consumption_price  :   this.state.update_item_consumption_price,
               
            };
            
           
            if(this.state.consumptionDetail) {
                this.setState({ saveFormSubmitting: true });
                
                HttpAPICall.withAthorization('PUT', this.CONSUMPTION_URL + '/edit', this.props.access_token, {}, formData, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/consumption_list`, state: {consumptionId : response.data.transaction_id }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            } else {
                this.setState({ saveFormSubmitting: true });
                HttpAPICall.withAthorization('POST', this.CONSUMPTION_URL + '/add', this.props.access_token, {}, formData, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/consumption_list`, state: {consumptionId : response.data.transaction_id }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
        }
    }
    
    pageTitlejsx                        =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3>{this.state.consumptionDetail ? "Edit" : "Add"} Consumption {this.state.consumptionDetail ? <span> of Transaction ID : {this.state.consumptionDetail.transaction_id}</span> : null}</h3>
                <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    consumptionItemsJsx                 =   ()  =>  {
        return (<div className="my-3 row">
            <div className="col-sm-12">
                <table className="table table-bordered table-responsive bg-white ">
                    <thead className="table-secondary align-middle">
                        <tr className="">
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" className="text-center" style={{ width: "27%" }}>Item Details</th>
                            <th scope="col" className="text-center" style={{ width: "14%" }}>Demand</th>
                            <th scope="col" className="text-center" style={{ width: "14%" }}>Allocation</th>
                            {this.state.track_batch_expiry == "Y" ?
                                <th scope="col" className="text-center" style={{ width: "12%" }}>Batch Number <br /> Expiry Date</th>
                                : null}
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Quantity</th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Unit Rate</th>
                            <th scope="col" className="text-center" style={{ width: "7%" }}>Amount</th>
                            <th scope="col" className="" style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.consumptionItemRows.map((itemRow, itemKey) => {
                            let item = itemRow && itemRow.item ? itemRow.item : null;
                          
                            return <tr className="" key={itemKey}>
                                <td className="text-center align-middle">{itemKey + 1}</td>
                                {(item
                                    ? <Ax>
                                        <td>
                                            <div className="row">
                                            <div className='col-sm-10'><span className="fw-bold link-primary cursor_pointer" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>{item.name}</span></div>
                                               
                                                <div className='col-sm-2 text-end'>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li><a href="#" className="dropdown-item" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>View Details</a></li>
                                                        {this.props?.permissions?.permissions?.includes("isc-item-edit") &&  <li><a href="#" className="dropdown-item" onClick={() => {this.setState({showItemEditModal : false},() => {this.itemViewModalInit(item, itemKey); this.editItemModalInit(item)})}}>Edit Item</a></li>}
                                                        <li><a href="#" className="dropdown-item" onClick={() => { this.viewConsumptionModalInit(item, itemKey) }}>Consumption History</a></li>
                                                        <li className="dropdown-item" onClick={() => { this.addRemark(itemRow.item, itemKey) }}>Add Remark</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="form-text"><b>Code: </b>{item.item_code}</div>
                                            <div className="form-text">
                                                <b>Mfr: </b>{item.manufacturer ? item.manufacturer.name : '-'} (<b>MPN:</b> {item.manufacturer_part_no ? item.manufacturer_part_no : '-'})
                                            </div>
                                            {itemRow.item && itemRow.item.isAddRemark && itemRow.item.isAddRemark == true
                                                ?
                                                <input
                                                    name="remark"
                                                    type="text"
                                                    value={itemRow.remark}
                                                    onChange={(e) => this.dynamicInputHandlerByKey(e, itemKey, 'consumptionItemRows')}
                                                    className="form-control mt10"
                                                    autoComplete="off"
                                                    placeholder="Item Remarks"
                                                />
                                                : null}

                                        </td>
                                      
                                        {
                                            itemRow.stock_loading && item.category == "goods"
                                                ? <td colSpan={this.state.track_batch_expiry == 'Y' ? 5 : 4}><Loader /></td>
                                                : (<Ax>
                                                     <td>
                                                       
                                                        {itemRow.selected_allocation && itemRow.selected_allocation.allocation_detail && itemRow.selected_allocation.allocation_detail.demand_transaction_id ?
                                                            <input className="form-control" value={itemRow.selected_allocation.allocation_detail.demand_transaction_id} disabled={true} />
                                                            : <TapSelect
                                                                changeEvent={(selectedOption, e) => { this.onDemandChange(selectedOption, itemKey,itemRow.qty); }}
                                                                options={itemRow.item_demand_list}
                                                                isSearchable={true}
                                                                placeholder="Item Demand"
                                                                isClearable={true}
                                                                isDisabled={itemRow.selected_allocation && itemRow.selected_allocation.allocation_item_id}
                                                                value={itemRow.item_demand_list && itemRow.item_demand_list.length > 0 ? itemRow.item_demand_list.find(m => m.value == itemRow.demand_item_id) : ''}
                                                            />}
                                                        <small className={['my-1',].join(' ')}>
                                                            {itemRow.demand_item_id && itemRow.demand_pending_qty &&<><b>Pending Qty: </b> {itemRow.demand_pending_qty} <br /></>}
                                                        </small>

                                                    </td>
                                                    
                                                    <td> {itemRow && itemRow.allocation_list && itemRow.allocation_list.length > 0 ?
                                                        <TapSelect
                                                            changeEvent={(selectedOption, e) => { this.onAllocationChange(selectedOption, itemKey,itemRow.qty) }}
                                                            options={itemRow.allocation_list}
                                                            isSearchable={false}
                                                            placeholder="Change"
                                                            isClearable={true}
                                                            isDisabled={itemRow.demand_item_id && !itemRow.selected_allocation && !itemRow?.selected_allocation?.allocation_item_id}
                                                            value={itemRow.allocation_list.find(s => s.value == itemRow?.selected_allocation?.allocation_item_id)}

                                                        />
                                                        : "-"}
                                                        {itemRow.selected_allocation && itemRow.selected_allocation.pending_qty > 0 ? <div className='py-1'> <b>Pending Qty: </b> {itemRow.selected_allocation ? itemRow.selected_allocation.pending_qty : "Nil"} <br /></div> : null}
                                                    </td>
                                                    {this.state.track_batch_expiry == "Y"
                                                        ? <td>
                                                            {item.category == "goods" ? (<Ax>
                                                                {itemRow && itemRow.current_stocks && itemRow.current_stocks.length > 1 ?
                                                                    <TapSelect
                                                                        changeEvent={(selectedOption) => { this.onBatchChange(selectedOption, itemKey); }}
                                                                        options={itemRow.current_stocks}
                                                                        isSearchable={false}
                                                                        placeholder="Change"
                                                                        isDisabled={itemRow && (itemRow.consumption_item_id || itemRow?.selected_allocation?.allocation_item_id) ? true : false}
                                                                    />
                                                                    : null}
                                                                <div className='py-1'>
                                                                    <b >BN : </b> {itemRow.selected_stock ? itemRow.selected_stock.batch_number : itemRow.selected_allocation ? itemRow.selected_allocation.batch_number ?? "-" : "-"} <br />
                                                                    <b >ED : </b> {itemRow.selected_stock ? itemRow.selected_stock.expiry_date_display : itemRow.selected_allocation ? itemRow.selected_allocation.expiry_date_display ?? "-" : "-"} <br />

                                                                </div>
                                                            </Ax>) : (<div className="align-middle text-center">-</div>)}
                                                        </td> : null
                                                    }
                                                    <td>
                                                        <TapInputNumber
                                                            name="qty"
                                                            value={itemRow.qty}
                                                            onChange={(e) => this.dynamicInputHandlerByKey(null, itemKey, 'consumptionItemRows',null,'qty',e)}
                                                            className="form-control text-end"
                                                            placeholder="Qty"
                                                            required={true}
                                                            min={.01}
                                                        />
                                                        
                                                        <span className="text-helping input-group-text text-end pr0">{item && item.measuring_unit ? item.measuring_unit.name : ''}</span>
                                                        {item.category == "services" ? null : <small className={['my-1', (itemRow.qty > (!itemRow.allocation_item_id ? itemRow.free_qty : itemRow.selected_allocation && itemRow.selected_allocation.pending_qty ? itemRow.selected_allocation.pending_qty : 0)) ? 'text-danger' : ''].join(' ')}>
                                                            {!itemRow.allocation_item_id ? <Ax>
                                                                <b>Stock In Hand: </b> {itemRow.selected_stock ? itemRow.stock_qty : "Nil"} <br />
                                                                <b >Free Stock: </b> {itemRow.selected_stock ? itemRow.free_qty : "Nil"} <br />
                                                            </Ax> : null}

                                                        </small>}
                                                    </td>
                                                    <td>
                                                        <TapInputNumber
                                                            name="rate"
                                                            value={itemRow.rate}
                                                            onChange={(e) => this.dynamicInputHandlerByKey(null, itemKey, 'consumptionItemRows',null,'rate',e)}
                                                            placeholder="Unit Rate"
                                                            required={true}
                                                            min={0}
                                                        />
                                                    </td>
                                                    <td className="pt-4 text-end">{(itemRow.rate * itemRow.qty).toFixed(2)}</td>
                                                </Ax>)}
                                    </Ax>
                                    : <Ax>
                                        <td className="align-middle">
                                            <InputItemSearch
                                                changeEvent={(selectedOption) => {
                                                    this.onItemSelect(selectedOption, itemKey);
                                                }}
                                                only_active_item='Y'
                                                only_stocked_item={this.state.only_stocked_item}
                                                stock_warehouse_ids={[this.state.consumptionForm.warehouse_id]}
                                            />
                                        </td>
                                        <td colSpan={this.state.track_batch_expiry == 'Y' ? 6 : 5}></td>
                                    </Ax>
                                )}
                                <td className="text-center align-middle">
                                    {this.state.consumptionItemRows.length > 1
                                        ? <a href="#" onClick={() => this.removeItemRow(itemKey,itemRow?.selected_allocation?.allocation_item_id,itemRow.demand_item_id)}>
                                            <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                        </a>
                                        : null
                                    }
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={this.state.only_stocked_item == "Y" ? "N" : "Y"}
                                checked={this.state.only_stocked_item == "Y"}
                                onChange={(e) => { this.setState({ only_stocked_item: e.target.value, }); }}
                                id="onlyStockedItem"
                            />
                            <label className="form-check-label" htmlFor="onlyStockedItem">
                                Show only those items that are available in the selected warehouse
                            </label>
                        </div>
                    </div>
                    <div className="col-sm-4 text-end">
                    {this.state.consumptionForm && this.state.consumptionForm.warehouse_id  ?
                    <a role="button"  onClick={() => {this.setState({showItemSearchModal : false},() => this.itemSearchModalInit())}} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> Search Item </a>
                    : null} 
                     {this.props?.permissions?.permissions?.includes("isc-item-add") &&    <a role="button" onClick={() => {this.setState({showItemAddModal : false},() => this.addItemModalInit())}} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item</a>}
                        <a role="button" onClick={this.addNewItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                    </div>
                    <div className='col-sm-12'>
                        <input
                            name="update_item_consumption_price"
                            type="checkbox"
                            value={this.state.update_item_consumption_price}
                            onChange={(e) => {

                                this.setState({
                                    update_item_consumption_price: this.state.update_item_consumption_price == 'N' ? 'Y' : 'N',
                                })
                            }}
                            checked={this.state.update_item_consumption_price == 'Y'}
                            className="form-check-input"
                            id="update_item_consumption_price"

                        />
                        <label className="form-check-label mx-2 text-sm" htmlFor="update_item_consumption_price">Update Item Consumption Price </label><br />
                    </div>
                </div>
            </div>
        </div>);
    }
    

    handleConsumptionFor = () => {
        this.setState({
            consumptionForm :   {...this.state.consumptionForm,
            ticket_id       :   this.state.ticketFromConsumption ? this.state.selectedTicket : '',
            asset_id        :   this.state.ticketFromConsumption && this.state.consumptionForm.asset_id ? this.state.consumptionForm.asset_id : null},
            selectedTicket  :   this.state.ticketFromConsumption ? this.state.selectedTicket : null,
            selectedAsset   :   this.state.ticketFromConsumption && this.state.consumptionForm.asset_id ? this.state.selectedAsset : null,
            selectedTicket  :   this.state.ticketFromConsumption ? this.state.selectedTicket : null,
            searchedWorkstation :   null,
            searchedEmployee    :   null,
            searchSubassembly   :   null,
        })
    }


    saveConsumptionFormJsx              =   ()  =>  {
        let sub_total_amount                =   0;
        this.state.consumptionItemRows.forEach((item,key) => {
            if(item && item.item) {
                    sub_total_amount     +=  item.rate * item.qty;
            }
        });

        let transactionDate = this.state.consumptionForm && this.state.consumptionForm.transaction_date ?  moment(this.state.consumptionForm.transaction_date).format('YYYY-MM-DD 00:00:00') : null;
        let disable_consumption_for = false;
        if(this.state.consumptionDetail && this.state.consumptionItemRows && this.state.consumptionItemRows.length > 0){
            disable_consumption_for = this.state.consumptionItemRows.some(item => item.selected_allocation !== null);
            
        }

        let ticketLinkedFromAllocation = this.state.ticketLinkedFromAllocation

        let filter_site_ids = [];
       
        if(this.state.selectedAsset && this.state.selectedAsset.site_id){
            filter_site_ids = [this.state.selectedAsset.site_id];
        }else if(this.state.searchedWorkstation && this.state.searchedWorkstation.workstation && this.state.searchedWorkstation.workstation.site_id){
            filter_site_ids = [this.state.searchedWorkstation.workstation.site_id];
        }else if(this.state.searchedEmployee && this.state.searchedEmployee.employee && this.state.searchedEmployee.employee.site ){
            filter_site_ids = [this.state.searchedEmployee.employee.site.site_id];
        }else{
            filter_site_ids = this.state.warehouseSites.map((w) => w.id);
        }

        
      return (<form className="bg-white p-3" onSubmit={this.consumptionFormSubmitHandler}>
           
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Date of Transaction</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.consumptionForm.transaction_date
                                ? moment(this.state.consumptionForm.transaction_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="transaction_date"
                        onChange={(value, event) => this.formDateHandler('transaction_date', value, 'consumptionForm')}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Date of Transaction`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Warehouse</label>
                </div>
                <div className="col-sm-7">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'consumptionForm', 'warehouse_id', () => {
                                this.onWarehouseChange(selectedOption);
                            });
                        }}
                        options={this.state.allWarehouses}
                        isSearchable={true}
                        required={true}
                        value={this.state.allWarehouses.find(m => m.value == this.state.consumptionForm.warehouse_id)}
                        placeholder="Please Select Warehouse"
                        isDisabled={this.state.consumptionDetail || ticketLinkedFromAllocation}
                    />
                </div>
            </div>
            {this.state.warehouseDetail
                ? <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8"><WarehouseAddress warehouseDetail={this.state.warehouseDetail} /></div>
                </div>
                : (this.state.warehouseDetailLoading ? <Loader /> : null)
            }
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Status</label>
                </div>
                <div className="col-sm-7">
                    <TapSelect
                         changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'consumptionForm', 'status');
                        }}
                        options={this.state.allStatus}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allStatus.find(s => s.value == this.state.consumptionForm.status)}
                        placeholder="Select Status"
                        isDisabled={this.state.consumptionDetail}
                    />
                </div>
            </div>
          <div className="row my-3">
              <div className="col-sm-3">
                  <label className="form-label require">Consumption For</label>
              </div>
              <div className="col-sm-4">
                  <TapSelect
                      changeEvent={(selectedOption) => {
                          this.tapSelectChange(selectedOption, 'consumptionForm', 'consumption_type', () => {
                              this.setState({
                                  selectedAsset: null,
                                  searchedWorkstation: null,
                                  searchedEmployee: null,
                                  searchSubassembly: null,

                                  consumptionForm: {
                                      ...this.state.consumptionForm,
                                      workstation_id: '',
                                      asset_id: '',
                                      employee_id: '',
                                      sub_assembly_id: ''
                                  },
                              })
                          });
                      }}
                      options={this.state.allConsumptionFor}
                      isSearchable={true}
                      isClearable={true}
                      required={true}
                      isDisabled={this.state.consumptionForm.ticket_id}
                      value={this.state.allConsumptionFor.find(s => s.value == this.state.consumptionForm.consumption_type)}
                      placeholder="Select Consumption For"
                  />
              </div>
              <div className="col-sm-4">
                  {this.state.consumptionForm.consumption_type == "asset"
                      ? <Ax>
                          <InputAssetSearch
                              changeEvent={(selectedOption) => {
                                  this.tapSelectChange(selectedOption, 'consumptionForm', 'asset_id');
                                  this.setState({ selectedAsset: selectedOption ? { ...selectedOption.asset, label: selectedOption.display_label } : null });
                              }}
                              placeholder="Search Asset"
                              search_site_id={this.state.warehouseSites.map((w) => w.id)}
                              filter={true}
                              required={true}
                              disabled={this.state.consumptionForm.ticket_id}
                              value={this.state.selectedAsset ? { ...this.state.selectedAsset } : null}
                          />

                          {this.state.selectedAsset
                              ?
                              <AssetMiniDetail assetDetail={this.state.selectedAsset} />

                              : null
                          }
                      </Ax>
                      : this.state.consumptionForm.consumption_type == "workstation"
                          ? <Ax>
                                 <InputWorkstationSearch
                                    placeholder="Please Select Workstation"
                                    changeEvent={(selectedOption) => {
                                        this.setState({
                                            searchedWorkstation : selectedOption ? selectedOption : null,
                                            consumptionForm : { ...this.state.consumptionForm, workstation_id: selectedOption ? selectedOption.value : '' }
                                        })

                                    }}
                                    menuPlacement="top"
                                    disabled={this.state.consumptionForm.ticket_id}
                                    search_site_id={this.state.warehouseSites.map(wk => wk.id)}
                                    value={this.state.searchedWorkstation ? this.state.searchedWorkstation : null}
                                />
                          </Ax>
                          : this.state.consumptionForm.consumption_type == "employee"
                              ? <Ax>
                                  {this.props.permissions.group_modules.includes("hrm")
                                      ?
                                      <InputEmployeeSearch
                                          placeholder="Please Select Employee"
                                          search_site_ids={this.state.warehouseSites.map((w) => w.id)}
                                          changeEvent={(selectedOption) => {
                                              this.tapSelectChange(selectedOption, 'consumptionForm', 'employee_id');
                                              this.setState({ searchedEmployee: selectedOption ? selectedOption : null });
                                          }}
                                          transaction_date={transactionDate ? transactionDate : null}
                                          disabled={this.state.consumptionForm.ticket_id}
                                          value={this.state.searchedEmployee ? this.state.searchedEmployee : null}
                                      />

                                      : null}
                              </Ax> : this.state.consumptionForm.consumption_type == "sub_assembly"
                                  ? <Ax>
                                      <InputSubAssemblySearch
                                          placeholder="Search Sub Assembly"
                                          search_site_id={this.state.warehouseSites.map((w) => w.id)}
                                          changeEvent={(selectedOption) => {
                                              this.tapSelectChange(selectedOption, 'consumptionForm', 'sub_assembly_id');
                                              this.setState({ searchSubassembly: selectedOption ? selectedOption : null });
                                          }}
                                          required={true}
                                          menuPlacement="top"
                                          isMulti={false}
                                          filter={true}
                                          disabled={this.state.consumptionForm.ticket_id}
                                          value={this.state.searchSubassembly ? this.state.searchSubassembly : null}
                                      />

                                  </Ax> : null
                  }
              </div>
          </div>
          
            {this.state.ticket_consumption == 'Y' && 
            <div className="row align-items-center my-3">
            <div className="col-sm-3">
                <label htmlFor="warehouse_id" className="form-label">Ticket</label>
            </div>
            <div className="col-sm-4">
            <InputTicketSearch
                    changeEvent={(selectedOption) => {
                        let warehouseList = this.state.allWarehouses
                        if(selectedOption && selectedOption.data){
                            warehouseList = warehouseList.filter(st =>  st.site_id.includes(selectedOption.data.site.id))       
                        }else{
                            warehouseList = this.props.all_warehouses && this.props.all_warehouses.length > 0 ? this.props.all_warehouses.map((s) => { return {value: s.id, label: `${s.name} (${s.code})` , site_id : s.site_ids}; }) : []
                        }
                       this.setState({ 
                           consumptionForm : {
                                ...this.state.consumptionForm,
                                ticket_id   :   selectedOption && selectedOption.value ? selectedOption.value : '',
                                sub_assembly_id : selectedOption && selectedOption.data && selectedOption.data.subAssembly ? selectedOption.data.subAssembly.enc_id : this.state.consumptionForm.sub_assembly_id,
                                workstation_id : selectedOption  && selectedOption.data && selectedOption.data.workStation ? selectedOption.data.workStation.id : this.state.consumptionForm.workstation_id,
                                employee_id : selectedOption  && selectedOption.data && selectedOption.data.employee ? selectedOption.data.employee.enc_id : this.state.consumptionForm.employee_id,
                                asset_id : selectedOption && selectedOption.asset ? selectedOption.asset.asset_id : this.state.consumptionForm.asset_id,
                                consumption_type   :  selectedOption && selectedOption.data && selectedOption.data.ticket_for ? selectedOption.data.ticket_for : this.state.consumptionForm.consumption_type
                            },
                            allWarehouses       :   warehouseList,
                            searchSubassembly   :   selectedOption && selectedOption.data && selectedOption.data.subAssembly ? {subAssembly :selectedOption.data.subAssembly, value : selectedOption.data.subAssembly.enc_id, label : `${selectedOption.data.subAssembly.name}`,display_label : `${selectedOption.data.subAssembly.name}` } : null,
                            searchedEmployee    :   selectedOption && selectedOption.data.employee ? {value : selectedOption.data.employee.enc_id, label : `${selectedOption.data.employee.name}` } : null,
                            selectedAsset       :   selectedOption  && selectedOption.asset ? {...selectedOption.asset, label : `${selectedOption.asset.name} (${selectedOption.asset.asset_code})` } : null,
                            searchedWorkstation     :   selectedOption && selectedOption.data.workStation ? {value : selectedOption.data.workStation.id, label : `${selectedOption.data.workStation.workstation_name}` } : null,
                            selectedTicket: selectedOption ? { ...selectedOption.asset,asset_id : selectedOption && selectedOption.asset ? selectedOption.asset.asset_id : '', label: selectedOption.display_label,data:selectedOption && selectedOption.data  ? selectedOption.data : null } : null,
                         },() => {
                            
                        });
                    }}
                    placeholder="Search Ticket"
                    search_site_id={this.state.warehouseSites.map(st => st.id)}
                    search_asset_id={this.state.consumptionForm.asset_id ? this.state.consumptionForm.asset_id : null }
                    search_employee_id={this.state.consumptionForm.employee_id ? this.state.consumptionForm.employee_id : null}
                    search_workstation={this.state.consumptionForm.workstation_id ? this.state.consumptionForm.workstation_id : null}
                    search_sub_assembly={this.state.consumptionForm.sub_assembly_id ? this.state.consumptionForm.sub_assembly_id : null}
                   // disabled={(!this.state.consumptionForm.warehouse_id)}
                    value={this.state.selectedTicket ? { ...this.state.selectedTicket } : null}
                    search_exclude_ticket_status={[0,26,10]}
                />

            </div>
        </div>}
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="voucher_number" className="form-label">Voucher Number & Date</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="voucher_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.consumptionForm.voucher_number}
                        onChange={(e) => this.formInputHandler(e, 'consumptionForm')}
                        placeholder="Enter Voucher Number"
                    />
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={this.state.consumptionForm.voucher_date ? moment(this.state.consumptionForm.voucher_date).toDate() : false}
                        name="voucher_date"
                        onChange={(value, event) => this.formDateHandler('voucher_date', value, 'consumptionForm')}
                        maxDate={moment().toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Enter Voucher Date`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="consumption_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3 ">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Allocate Item <br /></label>
                </div>
                <div className="col-sm-3">
                    <a role="button" className="link-primary" onClick={this.pickAllocationItemModalInit}><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Pick Item from Allocation </a>

                </div>
                <div className="col-sm-4">
                    <div className="form-check form-check-inline">
                        <input
                            name="include_item_demands"
                            type="checkbox"
                            value={this.state.include_item_demands == "Y" ? "N" : "Y"}
                            onChange={(e) => {
                                this.setState({
                                    include_item_demands: e.target.value,
                                    pendingPOListLoaded: false
                                });
                            }}
                            className="form-check-input"
                            id="include_item_demands"
                            checked={this.state.include_item_demands == "Y"}
                        />
                        <label className="form-check-label" htmlFor="iinclude_item_demands">
                            Include Approved Demand
                        </label>
                    </div>
                </div>
               

            </div>

            {this.consumptionItemsJsx()}
            <div className="row align-items-center my-3">
                <div className="col-sm-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.consumptionForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'consumptionForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-sm-6">
                    <table className="table table-borderless">
                        <tbody>
                            <tr className="subtotal_bg_color"><th>Sub Total</th><td className="text-end">{(sub_total_amount).toFixed(2)}</td></tr>
                            <tr className="subtotal_bg_color pt-2">
                                <th>Adjustment</th>
                                <td>
                                    <input
                                        name="adjustment_amount"
                                        type="number"
                                        className="form-control text-end"
                                        autoComplete="off"
                                        value={this.state.consumptionForm.adjustment_amount}
                                        onChange={(e) => this.formInputHandler(e, 'consumptionForm')}
                                        placeholder="Adjustment Amount"
                                    />
                                </td>
                            </tr>
                            <tr className="subtotal_bg_color">
                                <th>Final Total</th>
                                <td className="text-end">
                                    {this.state.consumptionForm.adjustment_amount
                                        ? (sub_total_amount + parseFloat(this.state.consumptionForm.adjustment_amount)).toFixed(2)
                                        : (sub_total_amount).toFixed(2)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          {!this.state.buttonDisabled &&  <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
                {/* {this.state.all
                    ? <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-secondary ms-2" onClick={(e) => { this.consumptionFormSubmitHandler(e, 1); }}>
                        Save as a Draft {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                    : null
                } */}
              
                <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} className="btn btn-primary ms-2" onClick={(e) => { this.consumptionFormSubmitHandler(e, this.state.consumptionForm.status); }}>
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>}
        </form>);
    }

    pickAllocationItemModalJsx              =   ()  =>  {
        
        return (
            <div className="modal fade" id="pickAllocationItemModal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="vendorDetailsModalLabel">Pick Item from Allocation && Item Demand </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <table className="table table-hover table-borderless my-2">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}>Warehouse</td>
                                        <th>{this.state.warehouseDetail && this.state.warehouseDetail.name ? this.state.warehouseDetail.name : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "25%" }}>Consumption For</td>
                                        <th className='text-capitalize'>{this.state.consumptionForm && this.state.consumptionForm.consumption_type ? this.state.consumptionForm.consumption_type : "-"}</th>
                                    </tr>


                                </tbody>
                            </table>
                            <form className="bg-white" onSubmit={this.submitSelectedAllocationItem} id="pickconsumptionForm" method="post" encType="multipart/form-data">
                                <table className="table table-hover table-bordered table-responsive table-sm bg-white mt-3">
                                    <thead className="table-secondary">
                                        <tr>
                                            <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllAllocationList} id="all_check_allocation" /></th>
                                            <th scope="col" className="text-start" style={{ width: "15%" }}>Item Name</th>
                                            <th scope="col" className="text-start" style={{ width: "15%" }}>Item Code</th>
                                            <th scope="col" className="text-center" style={{ width: "12%" }}>Demand ID</th>
                                            <th scope="col" className="text-center" style={{ width: "10%" }}>Demand Date</th>
                                            <th scope="col" className="text-center" style={{ width: "12%" }}>Allocation ID</th>
                                            <th scope="col" className="text-center" style={{ width: "10%" }}>Allocation Date</th>
                                            <th scope="col" className="text-center" style={{ width: "10%" }}>Total Qty</th>
                                            <th scope="col" className="text-center" style={{ width: "12%" }}>Pending Qty</th>
                                            <th scope="col" className="text-end" style={{ width: "7%" }}>Consume</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.pending_item_loading
                                            ? <tr><td colSpan={8}><Loader /></td></tr>

                                            : this.state.allPendingAllocationList && this.state.allPendingAllocationList.length > 0

                                                ? this.state.allPendingAllocationList.map((selectedItem, i) => {

                                                    return <tr>
                                                        <td className='text-center'>
                                                            <input type='checkbox'
                                                                className="allocation_id_checkbox"
                                                                name="allocationItem"
                                                                data-demandId={selectedItem.demand_item_id}
                                                                data-demandTransactionId={selectedItem.demand_transaction_id}
                                                                data-allocationTransactionId={selectedItem.allocation_transaction_id}
                                                                value={selectedItem.allocation_item_id}
                                                                data-itemId={selectedItem.item_id}
                                                                data-qty={selectedItem?.new_qty}
                                                                disabled={this.state.allAllocatedItemIds.find(element => {
                                                                    if (element?.allocation_item_id == selectedItem?.allocation_item_id && element?.demand_item_id == selectedItem?.demand_item_id) {
                                                                        return true;
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                })}
                                                                checked={this.state.allAllocatedItemIds.find(element => {
                                                                    if (element?.allocation_item_id == selectedItem?.allocation_item_id && element?.demand_item_id == selectedItem?.demand_item_id) {
                                                                        return true;
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                })}
                                                            />
                                                        </td>
                                                        <td>{selectedItem.item_name ? selectedItem.item_name : '-'}</td>
                                                        <td>{selectedItem.item_code ? selectedItem.item_code ?? "-" : '-'}</td>
                                                        <td className='text-center'>{selectedItem.demand_transaction_id ? selectedItem.demand_transaction_id : "-"}</td>
                                                        <td className='text-center'>{selectedItem.demand_transaction_date ? DateService.dateTimeFormat(selectedItem.demand_transaction_date ,'DD-MMM-YYYY') : "-"}</td>
                                                        <td className='text-center'>{selectedItem.allocation_transaction_id ? selectedItem.allocation_transaction_id : "-"}</td>
                                                        <td className='text-center'>{selectedItem.allocation_transaction_date ? DateService.dateTimeFormat(selectedItem.allocation_transaction_date ,'DD-MMM-YYYY') : "-"}</td>
                                                        
                                                        <td className='text-end'>{selectedItem.qty ? selectedItem.qty : "-"}</td>
                                                        <td className='text-end'>{selectedItem.pending_qty ? selectedItem.pending_qty : "-"}</td>
                                                        <td>
                                                            <TapInputNumber
                                                                className='form-control form-control-sm text-end'
                                                                placeholder='Allocated Quantity'
                                                                name="new_qty"
                                                                value={selectedItem.new_qty}
                                                                onChange={(e) => {
                                                                    this.state.allPendingAllocationList[i] = { ...selectedItem, new_qty: e };
                                                                    this.dynamicInputHandlerByKeyNameVal(e, 'new_qty', i, 'allPendingAllocationList')
                                                                }}
                                                                max={selectedItem.pending_qty}
                                                            />
                                                        </td>
                                                    </tr>
                                                })
                                                : <tr><td colSpan={10} className="text-center">No Record</td></tr>
                                        }

                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" disabled={this.state.savePickItemSubmitting} className="btn btn-primary" form="pickconsumptionForm">Add to Consumption {this.state.savePickItemSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
    
    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Add Consumption</title></Helmet>
            {this.pageTitlejsx()}
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                    { this.state.consumptionFormDataLoaded 
                        ? this.saveConsumptionFormJsx()
                        : <Loader/>
                    }
                    </div>
                </div>
            </div>
            {this.pickAllocationItemModalJsx()}
            {/* {this.consumptionHistoryModalJsx()} */}
            {this.state.showItemAddModal && <AddItemModal parentProps={this.props} ref={this.addItemModalRef} />}
            {this.state.showItemEditModal && <ItemEditModal parentProps={this.props} ref={this.editItemModalRef} afterSubmitItem={this.submitEditItem} />}
            <ItemDetailsModal itemData={this.state.itemSearchedDetails} closeEvent={this.itemDetailsClose} /> 
            <ConsumptionHistoryModal ref={this.consumptionHistoryRef}/>
            {this.state.showItemSearchModal && <ItemSearchModal ref={this.itemSearchRef}
                            afterSelectingItems={(selectedItems) => {
                                this.addItemsfromSearch(selectedItems);
                            }}
                        />}
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        iam_group_sites         :   state.app && state.app.user_sites ? state.app.user_sites : [],
        permissions            :   state.app.acl_info,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
    };
};

export default connect(mapStateToProps)(ConsumptionAdd);